<template>
  <div>
    <h2>Templates</h2>
    <Templates />
  </div>
</template>

<script>
import Templates from '@/components/Template/Templates.vue'

export default {
  components: {
    Templates,
  },
}
</script>
