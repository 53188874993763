var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Clone Template","centered":"","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('modal-closed')},"ok":_vm.onSubmit},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.submitting || _vm.loading || !_vm.profileName},on:{"click":function($event){return ok()}}},[_vm._v(" Clone "),(_vm.submitting)?_c('b-spinner',{attrs:{"small":"","label":"Small Spinner"}}):_vm._e()],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(!_vm.loading)?_c('div',[_c('validation-observer',{ref:"profileForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Template Name","label-for":"template-name","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"template-name","value":_vm.profileName,"readonly":""}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","label-cols-md":"4"}},[_c('b-form-input',{attrs:{"id":"name"},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.localTemplate.name),callback:function ($$v) {_vm.$set(_vm.localTemplate, "name", $$v)},expression:"localTemplate.name"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Project","vid":"project","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Project","label-for":"project","label-cols-md":"4","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"project","options":_vm.options.project},on:{"input":function($event){return _vm.onChangeProject(true)}},model:{value:(_vm.localTemplate.project),callback:function ($$v) {_vm.$set(_vm.localTemplate, "project", $$v)},expression:"localTemplate.project"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,574715774)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Country","vid":"country","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country","label-for":"country","label-cols-md":"4","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"project","options":_vm.options.country_code,"reduce":function (option) { return option.value; }},on:{"input":function($event){return _vm.onChangeProject(true)}},model:{value:(_vm.localTemplate.country),callback:function ($$v) {_vm.$set(_vm.localTemplate, "country", $$v)},expression:"localTemplate.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1080106940)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"doc_type","vid":"doc_type","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Document Type","label-for":"doc_type","label-cols-md":"4","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"doc_type","options":_vm.documentTypes},on:{"input":function($event){_vm.errorMessage = null}},model:{value:(_vm.localTemplate.doc_type),callback:function ($$v) {_vm.$set(_vm.localTemplate, "doc_type", $$v)},expression:"localTemplate.doc_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3526289139)})],1)],1),_c('b-alert',{staticClass:"my-1",attrs:{"variant":"danger","show":_vm.errorMessage !== null ? true : false}},[_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])])],1)],1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }